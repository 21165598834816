<template>
  <div class="mask">
    <div class="bg">
      <div class="topContainer">
        <div class="top">
          <el-form :inline="true" :model="form" class="demo-form-inline">
            <el-form-item label="设置名称">
              <el-input
                v-model="form.setName"
                clearable
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="box">
        <div style="width: 180px; height: 30px; color: #fff; font-size: 15px; border: #fff solid 1px; text-align: center; margin-top: 13px; margin-right: 10px; line-height: 30px;">
          全部
        </div>
        <el-table
          border
          fit
          :data="tableData"
          v-loading="tableLoadingStamp"
          height="calc(100vh - 280px)"
          width="100%"
        >
          <el-table-column
            fixed="left"
            type="index"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column
            fixed="left"
            label="业务模块"
            prop="business"
            align="center"
          ></el-table-column>
          <el-table-column
            label="设置名称"
            prop="setName"
            align="center"
          ></el-table-column>
          <el-table-column label="参数与说明" align="center">
            <template slot-scope="{ row }">
              <el-switch
                @change="changeVoiceBroadcast"
                v-model="switchObj.voiceBroadcast"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页区域 -->
        <!-- <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[8, 12, 16, 20, 24]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          style="width: 100%;margin: 10px 20px;"
          :total="total"
        ></el-pagination> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      tableDataOld: [
        {
          business: "语音播报",
          setName: "语音播报开关",
          fields: "voiceBroadcast"
        }
      ],
      form: {
        setName: ""
      },
      tableLoadingStamp: false,
      queryInfo: {
        condition: {},
        currPage: 1,
        pageSize: 8
      },
      total: 0,
      switchObj: {
        voiceBroadcast: false
      }
    };
  },
  mounted() {
    this.search();
    this.getSwitchObj();
  },
  methods: {
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getPowerStation();
    },
    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getPowerStation();
    },
    async changeVoiceBroadcast() {
      let isBroadcast = this.switchObj.voiceBroadcast 
      let res = await this.$http.post("/userInfo/updateBroadcast", {
        id: JSON.parse(localStorage.getItem("userInfo")).id,
        isBroadcast: isBroadcast == false ? 2 : 1
      });
      if (res.data.code == 200) {
      }
    },
    async getSwitchObj() {
      let res = await this.$http.post("/userInfo/queryOne", {
        userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
      });
      if (res.data.code == 200) {
        let data = res.data.data;
        let isBroadcast = data.isBroadcast == 1 ? true : false
        this.$set(this.switchObj, "voiceBroadcast", isBroadcast);
        console.log(this.switchObj)
      }
    },
    search() {
      this.tableData = this.tableDataOld.filter(item => {
        return item.setName.match(this.form.setName);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mask {
  background-color: #223f6c;
  min-height: calc(100vh - 85px);
  width: 100%;
  padding-top: 10px;
}
.bg {
  margin: 0 auto;
  padding: 0 10px;
  width: calc(100vw - 20px);
  background-color: #21527e;
}
// .topContainer {
//   width: 98vw;
//   background-color: #21527e;
// }
.box {
  width: 100%;
  height: calc(100vh - 195px);
  background-color: #21527e;
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  box-sizing: border-box;
  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid rgba(151, 179, 203, 0.55);
  }

  /deep/ .el-table--border th {
    background-color: #21527e;

    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
  }

  /deep/ .el-table th {
    background-color: #2d5981;
    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
  }

  /deep/ .el-table td {
    background-color: #2d5981;
    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
  }

  /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #000000 !important;
    // background-color: #223f6c !important;
  }

  /deep/ .el-table__body-wrapper {
    background: #2d5981;
  }

  // justify-content: space-between;
  .content {
    width: 25% !important;
    // background-color: #fff;
    padding: 10px 13px 5px;
    margin-bottom: 10px;

    .itemTop {
      display: flex;
      color: #fff;
      height: 50px;
      line-height: 50px;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;
      background-color: #00b2e6;
      font-size: 22px;
      font-weight: bold;

      .itemName {
        //溢出
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .itemContent {
      display: flex;
      padding: 40px 20px;
      box-sizing: border-box;
      background-color: #14304c;
      justify-content: space-around;
      color: #8c96a7;
      // height: 300px;
      font-size: 20px;

      .itemContent_left {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
    }
  }
}

.top {
  padding-top: 20px;
  box-sizing: border-box;
  text-align: end;
}

::v-deep .el-form-item__label {
  color: #fff;
}

::v-deep .el-input__inner {
  background-color: #223f6c !important;
  color: #fff !important;
}

.itemContent_right {
  text-align: center;
}

.itemContent_right_title {
  margin-top: 5px;
  font-size: 16px;
  color: #fff;
}

// ::v-deep .el-progress-circle {
//   width: 100px !important;
//   height: 100px!important;
// }
.sprop {
  position: relative;

  &::after {
    position: absolute;
    content: "消缺率";
    display: block;
    left: 50%;
    transform: translate(-50%, 150%);
    bottom: 50%;
    color: #d7d7d7;
    font-size: 16px;
  }
}

::v-deep .el-progress__text {
  // padding-left: 7px !important;
  font-size: 22px !important;
  color: #2acfa9 !important;
  padding-bottom: 20px;
}

::v-deep .el-progress-circle__track {
  stroke: #10596d; //#e5e9f2;
}

/deep/ .el-pagination button:disabled {
  background: rgba(255, 255, 255, 0);
}

/deep/ .el-pager li.active {
  color: #f2f2f2;
  background-color: #00b2e6;
  cursor: default;
}

/deep/ .el-pagination__total {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 12px;
}

/deep/.el-pager,
/deep/.btn-prev,
/deep/.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #fff;

  .number {
    background: rgba(255, 255, 255, 0);
  }
}

/deep/ .el-pagination__jump {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 15px;
}
</style>
